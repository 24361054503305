import AddCircle from 'plaid-threads/Icons/AddCircle';
import AlertWarning from 'plaid-threads/Icons/AlertWarning';
import Build from 'plaid-threads/Icons/Build';
import Changelog from 'plaid-threads/Icons/Changelog';
import Gear from 'plaid-threads/Icons/Gear';
import Guide from 'plaid-threads/Icons/Guide';
import Help from 'plaid-threads/Icons/Help';
import Home from 'plaid-threads/Icons/Home';
import IdentityIcon from 'plaid-threads/Icons/Identity';
import Lightbulb from 'plaid-threads/Icons/Lightbulb';
import Lightning from 'plaid-threads/Icons/Lightning';
import Mobile from 'plaid-threads/Icons/Mobile';
import ProductAssets from 'plaid-threads/Icons/ProductAssets';
import ProductAuth from 'plaid-threads/Icons/ProductAuth';
import ProductBalance from 'plaid-threads/Icons/ProductBalance';
import ProductIdentity from 'plaid-threads/Icons/ProductIdentity';
import ProductIncome from 'plaid-threads/Icons/ProductIncome';
import ProductInvestments from 'plaid-threads/Icons/ProductInvestments';
import ProductLiabilities from 'plaid-threads/Icons/ProductLiabilities';
import ProductTransactions from 'plaid-threads/Icons/ProductTransactions';
import Rocket from 'plaid-threads/Icons/Rocket';
import SandboxIcon from 'plaid-threads/Icons/Sandbox';
import Shield from 'plaid-threads/Icons/Shield';
import Terminal from 'plaid-threads/Icons/Terminal';
import User from 'plaid-threads/Icons/User';
import Wallet from 'plaid-threads/Icons/Wallet';

export interface NavItem {
  children?: Array<NavItem>;
  icon?: React.FC<React.ComponentProps<typeof SandboxIcon>>; // could use any icon
  path?: string;
  title?: string;
  disabled?: boolean;
  // show this article in the fallback
  // related articles section in the search modal
  featured?: boolean;
  highlight?: boolean; // whether it has an special active state when clicked on
  subNav?: boolean; // if false, does not show the subnav in the sidebar and keeps the top level nav
  loggedIn?: boolean; // If the navItem should only be displayed if the user is logged in
  flag?: string; // Experimental flags that we use for hidden features
}

export type NavGroup = Array<NavItem>;

export const Auth: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Auth',
        path: '/auth',
        featured: true,
      },
    ],
  },
  {
    children: [
      { title: 'Add Auth to your app', path: '/auth/add-to-app' },
      {
        title: 'Money movement partnerships',
        path: '/auth/partnerships',
        children: [
          // PROCESSOR_HEADER_START
          { title: 'Dwolla', path: '/auth/partnerships/dwolla' },
          { title: 'Square', path: '/auth/partnerships/square' },
          { title: 'ACHQ', path: '/auth/partnerships/achq' },
          { title: 'Roll by ADP', path: '/auth/partnerships/adp-roll' },
          { title: 'Adyen', path: '/auth/partnerships/adyen' },
          { title: 'Alpaca', path: '/auth/partnerships/alpaca' },
          { title: 'Ansa', path: '/auth/partnerships/ansa' },
          {
            title: 'Apex Fintech Solutions',
            path: '/auth/partnerships/apex-clearing',
          },
          { title: 'Astra', path: '/auth/partnerships/astra' },
          { title: 'Atomic', path: '/auth/partnerships/atomic' },
          { title: 'Bakkt', path: '/auth/partnerships/bakkt' },
          { title: 'Bond', path: '/auth/partnerships/bond' },
          { title: 'Check', path: '/auth/partnerships/check' },
          { title: 'Checkbook', path: '/auth/partnerships/checkbook' },
          { title: 'Checkout.com', path: '/auth/partnerships/checkout' },
          { title: 'DriveWealth', path: '/auth/partnerships/drivewealth' },
          { title: 'Finix', path: '/auth/partnerships/finix' },
          {
            title: 'Fortress Trust',
            path: '/auth/partnerships/fortress-trust',
          },
          { title: 'Galileo', path: '/auth/partnerships/galileo' },
          { title: 'Gusto', path: '/auth/partnerships/gusto' },
          { title: 'Highnote', path: '/auth/partnerships/highnote' },
          { title: 'Lithic', path: '/auth/partnerships/lithic' },
          { title: 'Marqeta', path: '/auth/partnerships/marqeta' },
          {
            title: 'Modern Treasury',
            path: '/auth/partnerships/modern-treasury',
          },
          { title: 'Moov', path: '/auth/partnerships/moov' },
          { title: 'Paynote', path: '/auth/partnerships/paynote' },
          { title: 'Riskified', path: '/auth/partnerships/riskified' },
          { title: 'Rize', path: '/auth/partnerships/rize' },
          { title: 'Sardine', path: '/auth/partnerships/sardine' },
          { title: 'ScribeUp', path: '/auth/partnerships/scribeup' },
          { title: 'Sila Money', path: '/auth/partnerships/sila-money' },
          { title: 'Silicon Valley Bank', path: '/auth/partnerships/svb' },
          { title: 'Solid', path: '/auth/partnerships/solid' },
          { title: 'Stake', path: '/auth/partnerships/stake' },
          { title: 'Straddle', path: '/auth/partnerships/straddle' },
          { title: 'Stripe', path: '/auth/partnerships/stripe' },
          { title: 'TabaPay', path: '/auth/partnerships/taba-pay' },
          {
            title: 'Treasury Prime',
            path: '/auth/partnerships/treasury-prime',
          },
          { title: 'Unit', path: '/auth/partnerships/unit' },
          { title: 'VoPay', path: '/auth/partnerships/vopay' },
          { title: 'Wedbush', path: '/auth/partnerships/wedbush' },
          { title: 'Zero Hash', path: '/auth/partnerships/zero-hash' },
          // PROCESSOR_HEADER_END
        ],
      },
      {
        title: 'Additional Auth flows',
        path: '/auth/coverage',
        children: [
          {
            title: 'Instant Auth, Instant Match, & Instant Micro-deposits',
            path: '/auth/coverage/instant',
          },
          {
            title: 'Automated Micro-deposits',
            path: '/auth/coverage/automated',
          },
          {
            title: 'Same Day Micro-deposits',
            path: '/auth/coverage/same-day',
          },
          {
            title: 'Micro-deposit Events',
            path: '/auth/coverage/microdeposit-events',
          },
          {
            title: 'Database Auth',
            path: '/auth/coverage/database-auth',
          },
          {
            title: 'Configuring entry points',
            path: '/auth/coverage/flow-options',
          },
          {
            title: 'Test in Sandbox',
            path: '/auth/coverage/testing',
          },
          {
            title: 'Anti-fraud best practices',
            path: '/auth/coverage/same-day-link-best-practices',
          },
          {
            title: 'Database Insights and Match (legacy)',
            path: '/auth/coverage/database',
          },
        ],
      },
    ],
  },
];

export const Transactions: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Transactions',
        path: '/transactions',
        featured: false,
      },
    ],
  },
  {
    children: [
      {
        title: 'Add Transactions to your app',
        path: '/transactions/add-to-app',
      },
      {
        title: 'Transactions webhooks',
        path: '/transactions/webhooks',
      },
      {
        title: 'Transaction states',
        path: '/transactions/transactions-data',
      },
      {
        title: 'Transactions Sync migration guide',
        path: '/transactions/sync-migration',
      },
      {
        title: 'Personal Finance Category migration guide',
        path: '/transactions/pfc-migration',
      },
      {
        title: 'Transactions partners',
        path: '/transactions/partnerships',
        children: [
          { title: 'Boom', path: '/transactions/partnerships/boom' },
          { title: 'Layer', path: '/transactions/partnerships/layer' },
          { title: 'Knot', path: '/transactions/partnerships/knot' },
          { title: 'Sardine', path: '/auth/partnerships/sardine' },
          { title: 'Teal', path: '/transactions/partnerships/teal' },
        ],
      },
    ],
  },
  {
    children: [
      {
        title: 'Troubleshooting',
        path: '/transactions/troubleshooting',
      },
    ],
  },
];

export const Identity: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Identity',
        path: '/identity',
        featured: false,
      },
      {
        title: 'Add Identity to your app',
        path: '/identity/add-to-app',
      },
      {
        title: 'Identity Document Upload',
        path: '/identity/identity-document-upload',
      },
      {
        title: 'Identity partners',
        path: '/identity/partnerships',
        children: [{ title: 'Alloy', path: '/identity/partnerships/alloy' }],
      },
    ],
  },
];

export const IdentityVerification: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Identity Verification',
        path: '/identity-verification',
        featured: false,
      },
    ],
  },
  {
    children: [
      { title: 'Webhooks', path: '/identity-verification/webhooks' },
      {
        title: 'Link callbacks',
        path: '/identity-verification/link',
      },
      {
        title: 'Testing in Sandbox',
        path: '/identity-verification/testing',
      },
      {
        title: 'Hybrid input validation',
        path: '/identity-verification/hybrid-input-validation',
      },
      {
        title: 'Generating metrics',
        path: '/identity-verification/reporting',
      },
    ],
  },
];

export const Layer: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Layer',
        path: '/layer',
        featured: false,
      },
    ],
  },
  {
    children: [
      {
        title: 'Add Layer to your app',
        path: '/layer/add-to-app',
        featured: false,
        subNav: false,
      },
    ],
  },
];

export const Monitor: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Monitor',
        path: '/monitor',
        featured: false,
      },
    ],
  },
  {
    children: [
      {
        title: 'Watchlists and matching algorithms',
        path: '/monitor/algorithms-and-lists',
      },
    ],
  },
];

export const Beacon: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Beacon',
        path: '/beacon',
        featured: false,
      },
    ],
  },
];

export const Transfer: NavGroup = [
  {
    children: [
      {
        title: 'Transfer Overview',
        path: '/transfer',
        featured: false,
      },
      {
        title: 'Transfer Application',
        path: '/transfer/application',
      },
      {
        title: 'Creating transfers',
        path: '/transfer/creating-transfers',
      },
      {
        title: 'Monitoring transfers',
        path: '/transfer/reconciling-transfers',
      },
      {
        title: 'Plaid Ledger flow of funds',
        path: '/transfer/flow-of-funds',
      },
      {
        title: 'Transfer Dashboard',
        path: '/transfer/dashboard',
      },
      {
        title: 'Refunds',
        path: '/transfer/refunds',
      },
      {
        title: 'Recurring transfers',
        path: '/transfer/recurring-transfers',
      },
      {
        title: 'Transfer UI',
        path: '/transfer/using-transfer-ui',
      },
      {
        title: 'Platform Payments',
        path: '/transfer/platform-payments',
      },
      {
        title: 'Errors and troubleshooting',
        path: '/transfer/troubleshooting',
      },
      {
        title: 'Testing in Sandbox',
        path: '/transfer/sandbox',
      },
      {
        title: 'Legacy Flow of funds',
        path: '/transfer/legacy-flow-of-funds',
      },
    ],
  },
];

export const BankTransfers: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Bank Transfers',
        path: '/bank-transfers',
      },
      {
        title: 'Idempotency and Retries',
        path: '/bank-transfers/idempotency-and-retries',
      },
      {
        title: 'Webhooks in Bank Transfers',
        path: '/bank-transfers/webhook-events',
      },
      {
        title: 'API Reference',
        path: '/bank-transfers/reference',
      },
    ],
  },
];

export const Income: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Income',
        path: '/income',
        featured: false,
      },
      {
        title: 'Bank Income',
        path: '/income/bank-income',
      },
      {
        title: 'Document Income',
        path: '/income/document-income',
      },
      {
        title: 'Payroll Income',
        path: '/income/payroll-income',
      },
      {
        title: 'Add Income to your app',
        path: '/income/add-to-app',
      },
    ],
  },
];

export const Enrich: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Enrich',
        path: '/enrich',
        featured: false,
      },
    ],
  },
];

export const Statements: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Statements',
        path: '/statements',
        featured: false,
      },
    ],
  },
];

export const TransactionsPlus: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Transactions+',
        path: '/transactions-plus',
        featured: false,
      },
    ],
  },
];

export const Assets: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Assets',
        path: '/assets',
        featured: false,
      },
      { title: 'Create an Asset Report', path: '/assets/add-to-app' },
      {
        title: 'Assets partners',
        path: '/assets/partnerships',
        children: [
          { title: 'Alloy', path: '/assets/partnerships/alloy' },
          { title: 'Ocrolus', path: '/assets/partnerships/ocrolus' },
        ],
      },
    ],
  },
];
export const Balance: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Balance',
        path: '/balance',
        featured: true,
      },
      {
        title: 'Add Balance to your app',
        path: '/balance/add-to-app',
      },
      { title: 'Troubleshooting', path: '/balance/troubleshooting' },
    ],
  },
];

export const Signal: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Signal',
        path: '/signal',
        featured: true,
      },
      {
        title: 'Add Signal to your app',
        path: '/signal/add-to-app',
      },
    ],
  },
];

export const Liabilities: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Liabilities',
        path: '/liabilities',
        featured: false,
      },
      {
        title: 'Add Liabilities to your app',
        path: '/liabilities/add-to-app',
      },
    ],
  },
];

export const Investments: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Investments',
        path: '/investments',
        featured: false,
      },
      {
        title: 'Add Investments to your app',
        path: '/investments/add-to-app',
      },
    ],
  },
];

export const InvestmentsMove: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Investments Move',
        path: '/investments-move',
        featured: false,
      },
      {
        title: 'Add Investments Move to your app',
        path: '/investments-move/add-to-app',
        featured: false,
      },
    ],
  },
];

export const PaymentInitiation: NavGroup = [
  {
    children: [
      {
        title: 'Introduction to Payment Initiation',
        path: '/payment-initiation',
        featured: true,
      },
      {
        title: 'Add Payment Initiation to your app',
        path: '/payment-initiation/add-to-app',
      },
      {
        title: 'User onboarding and account funding',
        path: '/payment-initiation/user-onboarding-and-account-funding',
      },
      {
        title: 'Add Variable Recurring Payments to your app',
        path: '/payment-initiation/vrp',
      },
    ],
  },
  {
    children: [
      {
        title: 'Virtual Accounts',
        path: '/payment-initiation/virtual-accounts',
        children: [
          {
            title: 'Managing your Virtual Account',
            path: '/payment-initiation/virtual-accounts/managing-virtual-accounts',
          },
          {
            title: 'Payment Confirmation',
            path: '/payment-initiation/virtual-accounts/payment-confirmation',
          },
          {
            title: 'Payouts',
            path: '/payment-initiation/virtual-accounts/payouts',
          },
          {
            title: 'Refunds',
            path: '/payment-initiation/virtual-accounts/refunds',
          },
          {
            title: 'Account Sweeping',
            path: '/payment-initiation/virtual-accounts/account-sweeping',
          },
          {
            title: 'Add Virtual Accounts to your app',
            path: '/payment-initiation/virtual-accounts/add-to-app',
          },
        ],
      },
    ],
  },
];

export const Account: NavGroup = [
  {
    children: [
      { title: 'Overview', path: '/account' },
      { title: 'Teams', path: '/account/teams' },
      { title: 'Security', path: '/account/security' },
      { title: 'Pricing and billing', path: '/account/billing' },
      {
        title: 'Activity, logs, and status',
        path: '/account/activity',
      },
      { title: 'Resellers', path: '/account/resellers' },
    ],
  },
];

export const Resources: NavGroup = [
  {
    children: [{ title: 'Resources', path: '/resources' }],
  },
];

export const Support: NavGroup = [
  {
    children: [{ title: 'Overview', path: '/support' }],
  },
];
export const Quickstart: NavGroup = [
  {
    children: [
      { title: 'Quickstart', path: '/quickstart', featured: true },
      { title: 'Glossary', path: '/quickstart/glossary' },
    ],
  },
];

export const Launch = [];

export const Check: NavGroup = [
  {
    children: [
      { title: 'Introduction', path: '/check' },
      { title: 'Implementation', path: '/check/add-to-app' },
      { title: 'Migrate from Assets', path: '/check/migrate-from-assets' },
      {
        title: 'Onboard users with Layer',
        path: '/check/onboard-users-with-layer',
      },
    ],
  },
];

export const Sandbox = [
  {
    children: [
      { title: 'Overview', path: '/sandbox' },
      {
        title: 'Sandbox institutions',
        path: '/sandbox/institutions',
      },
      {
        title: 'Test credentials',
        path: '/sandbox/test-credentials',
      },
      { title: 'Customize test data', path: '/sandbox/user-custom' },
    ],
  },
];

export const APIReference: NavGroup = [
  {
    children: [
      { title: 'Overview', path: '/api', featured: true },
      { title: 'Libraries', path: '/api/libraries' },
      {
        title: 'API versioning',
        path: '/api/versioning',
      },
      { title: 'Postman Collection', path: '/api/postman' },
      {
        title: 'Webhooks',
        path: '/api/webhooks',
        featured: true,
        children: [
          {
            title: 'Webhook verification',
            path: '/api/webhooks/webhook-verification',
          },
        ],
      },
    ],
  },
  {
    title: 'Payments and Funding',
    children: [
      { title: 'Auth', path: '/api/products/auth' },
      { title: 'Balance', path: '/api/products/balance' },
      { title: 'Identity', path: '/api/products/identity' },
      {
        title: 'Signal',
        path: '/api/products/signal',
      },
      {
        title: 'Transfer',
        path: '/api/products/transfer',
        featured: true,
        subNav: true,
        children: [
          {
            title: 'Initiating Transfers',
            path: '/api/products/transfer/initiating-transfers',
          },
          {
            title: 'Reading Transfers',
            path: '/api/products/transfer/reading-transfers',
          },
          {
            title: 'Account Linking',
            path: '/api/products/transfer/account-linking',
          },
          {
            title: 'Recurring Transfers',
            path: '/api/products/transfer/recurring-transfers',
          },
          { title: 'Refunds', path: '/api/products/transfer/refunds' },
          {
            title: 'Platform Payments',
            path: '/api/products/transfer/platform-payments',
          },
          { title: 'Plaid Ledger', path: '/api/products/transfer/ledger' },
          {
            title: 'Program Metrics',
            path: '/api/products/transfer/metrics',
          },
          { title: 'Sandbox', path: '/api/sandbox/' },
        ],
      },
      { title: 'Investments Move', path: '/api/products/investments-move' },
      {
        title: 'Payment Initiation',
        path: '/api/products/payment-initiation',
      },
      {
        title: 'Virtual Accounts',
        path: '/api/products/virtual-accounts',
      },
    ],
  },
  {
    title: 'Financial Insights',
    children: [
      { title: 'Transactions', path: '/api/products/transactions' },
      { title: 'Investments', path: '/api/products/investments' },
      { title: 'Liabilities', path: '/api/products/liabilities' },
      {
        title: 'Enrich',
        path: '/api/products/enrich',
      },
    ],
  },
  {
    title: 'KYC/AML and anti-fraud',
    children: [
      {
        title: 'Look up Dashboard users',
        path: '/api/kyc-aml-users',
      },
      {
        title: 'Identity Verification',
        path: '/api/products/identity-verification',
      },

      {
        title: 'Monitor',
        path: '/api/products/monitor',
      },
      {
        title: 'Beacon (beta)',
        path: '/api/products/beacon',
      },
    ],
  },
  {
    title: 'Instant Onboarding',
    children: [{ title: 'Layer', path: '/api/products/layer' }],
  },

  {
    title: 'Credit and Underwriting',
    children: [
      {
        title: 'Consumer Report (by Plaid Check)',
        path: '/api/products/check',
      },
      { title: 'Assets', path: '/api/products/assets' },
      {
        title: 'Statements',
        path: '/api/products/statements',
      },

      { title: 'Income', path: '/api/products/income' },
    ],
  },

  {
    title: 'Fundamentals',
    children: [
      { title: 'Items', path: '/api/items' },
      {
        title: 'Accounts',
        path: '/api/accounts',
      },
      { title: 'Institutions', path: '/api/institutions' },
      { title: 'Sandbox', path: '/api/sandbox' },
      { title: 'Link', path: '/api/link' },
      { title: 'Users', path: '/api/users' },
      { title: 'Consent', path: '/api/consent' },
      { title: 'Network', path: '/api/network' },
    ],
  },
  {
    title: 'Partnerships',
    children: [
      { title: 'Processor tokens', path: '/api/processors' },
      { title: 'Processor partners', path: '/api/processor-partners' },
      { title: 'Reseller partners', path: '/api/partner' },
    ],
  },
];

export const LinkReference: NavGroup = [
  {
    children: [{ title: 'Overview', path: '/link', featured: true }],
  },
  {
    title: 'Platforms',
    children: [
      { title: 'Web', path: '/link/web' },
      {
        title: 'iOS',
        path: '/link/ios',
      },
      {
        title: 'Android',
        path: '/link/android',
        children: [
          {
            title: 'Troubleshooting',
            path: '/link/android/troubleshooting',
            featured: false,
          },
        ],
      },
      {
        title: 'React Native',
        path: '/link/react-native',
      },
      {
        title: 'Hosted Link',
        path: '/link/hosted-link',
        children: [
          {
            title: 'Hosted Link for embedded clients',
            path: '/link/hosted-link/embedded-clients',
          },
        ],
      },
    ],
  },
  {
    title: 'Core Link functionality',
    children: [
      { title: 'OAuth guide', path: '/link/oauth', featured: true },
      { title: 'Update mode', path: '/link/update-mode' },
      {
        title: 'Preventing duplicate Items',
        path: '/link/duplicate-items',
      },
      {
        title: 'Data Transparency Messaging migration',
        path: '/link/data-transparency-messaging-migration-guide',
      },
      {
        title: 'Returning user experience',
        path: '/link/returning-user',
      },
    ],
  },
  {
    title: 'Additional Link modes',
    children: [
      {
        title: 'Embedded Link',
        path: '/link/embedded-institution-search',
      },
      {
        title: 'Multi-Item Link',
        path: '/link/multi-item-link',
      },
      {
        title: 'Link Recovery (beta)',
        path: '/link/link-recovery',
      },
      {
        title: 'Modular Link (UK/EU only)',
        path: '/link/modular-link-guide',
      },
    ],
  },
  {
    title: 'Optimizing Link',
    children: [
      {
        title: 'Optimizing Link conversion',
        path: '/link/best-practices',
      },
      {
        title: 'Link analytics and tracking',
        path: '/link/measuring-conversion',
      },
      {
        title: 'Pre-Link messaging',
        path: '/link/messaging',
      },
      { title: 'Customizing Link', path: '/link/customization' },
      {
        title: 'Choosing when to initialize products',
        path: '/link/initializing-products',
      },
    ],
  },

  {
    title: 'Errors and troubleshooting',
    children: [
      { title: 'Troubleshooting', path: '/link/troubleshooting' },
      {
        title: 'Handling an invalid Link Token',
        path: '/link/handle-invalid-link-token',
      },
      {
        title: 'Institution status in Link',
        path: '/link/institution-status',
      },
    ],
  },
  {
    title: 'Legacy flows',
    children: [
      {
        title: 'Legacy public key integrations',
        path: '/link/maintain-legacy-integration',
      },
      {
        title: 'Link Token migration guide',
        path: '/link/link-token-migration-guide',
      },
      { title: 'Webview integrations', path: '/link/webview' },
    ],
  },
];

export const Errors: NavGroup = [
  {
    children: [{ title: 'Overview', path: '/errors', featured: true }],
  },
  {
    children: [
      { title: 'Item errors', path: '/errors/item' },
      { title: 'Institution errors', path: '/errors/institution' },
      { title: 'Transactions errors', path: '/errors/transactions' },
      { title: 'Transfer errors', path: '/errors/transfer' },
      {
        title: 'Income errors',
        path: '/errors/income',
      },
      { title: 'Sandbox errors', path: '/errors/sandbox' },
      { title: 'API errors', path: '/errors/api' },
      { title: 'Assets errors', path: '/errors/assets' },
      { title: 'Payment errors (UK/EU)', path: '/errors/payment' },
      {
        title: 'Virtual Accounts errors (UK/EU)',
        path: '/errors/virtual-account',
      },
      { title: 'Check Report errors', path: '/errors/check-report' },
    ],
  },
  {
    children: [
      {
        title: 'Invalid Request errors',
        path: '/errors/invalid-request',
      },
      {
        title: 'Invalid Input errors',
        path: '/errors/invalid-input',
      },
      {
        title: 'Invalid Result errors',
        path: '/errors/invalid-result',
      },

      {
        title: 'Rate Limit Exceeded errors',
        path: '/errors/rate-limit-exceeded',
      },
      { title: 'Recaptcha errors', path: '/errors/recaptcha' },
      { title: 'OAuth errors', path: '/errors/oauth' },
      { title: 'Microdeposits errors', path: '/errors/microdeposits' },
      { title: 'Partner errors', path: '/errors/partner' },
    ],
  },
];

export const GENERAL: NavGroup = [
  {
    children: [
      { title: 'Home', path: '', icon: Home, highlight: true },
      {
        title: 'Quickstart',
        path: '/quickstart',
        children: Quickstart,
        icon: Rocket,
        highlight: true,
      },
      {
        title: 'API',
        path: '/api',
        children: APIReference,
        icon: Terminal,
      },
      {
        title: 'Link',
        path: '/link',
        children: LinkReference,
        icon: Mobile,
      },
      {
        title: 'Sandbox',
        path: '/sandbox',
        children: Sandbox,
        icon: SandboxIcon,
      },
      {
        title: 'Resources',
        path: '/resources',
        children: Resources,
        icon: Build,
      },
      {
        title: 'Errors',
        path: '/errors',
        children: Errors,
        icon: AlertWarning,
      },
      {
        title: 'Launch checklist',
        path: '/launch-checklist',
        icon: Shield,
      },
      {
        title: 'Account',
        path: '/account',
        children: Account,
        icon: IdentityIcon,
      },
      {
        title: 'Support',
        path: '/support',
        children: Support,
        icon: Help,
      },
      {
        title: 'Changelog',
        path: '/changelog',
        icon: Changelog,
        children: [],
      },
      {
        title: 'Docs Settings',
        path: '/settings',
        icon: Gear,
        children: [],
        subNav: false,
        loggedIn: true,
      },
    ],
  },
];

export const PAYMENT_PRODUCTS: NavGroup = [
  {
    title: 'Payments and Funding',
    icon: Wallet,
    path: '/payments',
    children: [
      {
        title: 'Auth',
        path: '/auth',
        children: Auth,
        icon: ProductAuth,
      },
      {
        title: 'Balance',
        path: '/balance',
        children: Balance,
        icon: ProductBalance,
      },
      {
        title: 'Identity',
        path: '/identity',
        children: Identity,
        icon: ProductIdentity,
      },
      {
        title: 'Signal',
        path: '/signal',
        children: Signal,
        icon: Shield,
      },
      {
        title: 'Transfer',
        path: '/transfer',
        children: Transfer,
        icon: ProductAuth,
      },
      {
        title: 'Investments Move',
        path: '/investments-move',
        children: InvestmentsMove,
      },
      {
        title: 'Payment Initiation (UK and Europe)',
        path: '/payment-initiation',
        children: PaymentInitiation,
        icon: AddCircle,
      },
      {
        title: 'Bank Transfers',
        path: '/bank-transfers',
        children: BankTransfers,
        icon: ProductAuth,
        disabled: true,
      },
    ],
  },
];

export const PFM_PRODUCTS: NavGroup = [
  {
    title: 'Financial Insights',
    icon: Lightbulb,
    path: '/financial-insights',
    children: [
      {
        title: 'Transactions',
        path: '/transactions',
        children: Transactions,
        icon: ProductTransactions,
      },

      {
        title: 'Investments',
        path: '/investments',
        children: Investments,
        icon: ProductInvestments,
      },
      {
        title: 'Liabilities',
        path: '/liabilities',
        children: Liabilities,
        icon: ProductLiabilities,
      },
      {
        title: 'Enrich',
        path: '/enrich',
        children: Enrich,
      },
    ],
  },
];

export const FRAUD_PRODUCTS: NavGroup = [
  {
    title: 'KYC/AML and anti-fraud',
    icon: User,
    path: '/kyc-aml',
    children: [
      {
        title: 'Identity Verification',
        path: '/identity-verification',
        children: IdentityVerification,
      },

      {
        title: 'Monitor',
        path: '/monitor',
        children: Monitor,
      },
      {
        title: 'Beacon',
        path: '/beacon',
        children: Beacon,
      },
    ],
  },
];

export const ONBOARDING_PRODUCTS: NavGroup = [
  {
    title: 'Instant Onboarding',
    icon: Lightning,
    path: '/layer',
    children: [
      {
        title: 'Layer',
        path: '/layer',
        children: Layer,
      },
    ],
  },
];
export const CREDIT_PRODUCTS: NavGroup = [
  {
    title: 'Credit and Underwriting',
    icon: ProductIncome,
    path: '/underwriting',
    children: [
      {
        title: 'Consumer Report (by Plaid Check)',
        path: '/check',
        children: Check,
        icon: ProductAssets,
      },
      {
        title: 'Assets',
        path: '/assets',
        children: Assets,
        icon: ProductAssets,
      },
      {
        title: 'Income',
        path: '/income',
        children: Income,
        icon: ProductIncome,
      },
      {
        title: 'Statements',
        path: '/statements',
        children: Statements,
      },
    ],
  },
];

// everything
export const ROUTE_MAP: NavGroup = [
  ...GENERAL,
  ...PAYMENT_PRODUCTS,
  ...PFM_PRODUCTS,
  ...FRAUD_PRODUCTS,
  ...ONBOARDING_PRODUCTS,
  ...CREDIT_PRODUCTS,
];

const flattenNavGroup = (group: NavGroup): Array<NavItem> =>
  group.reduce((acc, item) => {
    const res: Array<NavItem> = [];
    // if this item has properties,
    // push item to results
    if (item.path != null && item.title != null) {
      res.push(item);
    }
    // process children if they exist
    if (item.children != null) {
      res.push(...flattenNavGroup(item.children));
    }
    return acc.concat(res);
  }, []);

export const ROUTE_MAP_FLAT = flattenNavGroup(ROUTE_MAP);
