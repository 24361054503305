module.exports = {
  BLOG_ACCESS_TOKEN: process.env.BLOG_ACCESS_TOKEN,
  BLOG_SPACE_ID: process.env.BLOG_SPACE_ID,
  CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
  PARTNER_ACCESS_TOKEN: process.env.PARTNER_ACCESS_TOKEN,
  PARTNER_SPACE_ID: process.env.PARTNER_SPACE_ID,
  PERCY_TOKEN: process.env.PERCY_TOKEN,
  PLAID_ENV: process.env.PLAID_ENV,
  CONTENTFUL_HOST: process.env.CONTENTFUL_HOST,
  CONTENTFUL_ENVIRONMENT: process.env.CONTENTFUL_ENVIRONMENT,
  INSTITUTIONS_PUBLIC_KEY: process.env.INSTITUTIONS_PUBLIC_KEY,
  PORT: process.env.PORT,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_IS_SERVER: process.env.NEXT_IS_SERVER,
  NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR:
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
  PLAID_COMMIT: process.env.PLAID_COMMIT,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
  MARKETO_BASE_URL: process.env.MARKETO_BASE_URL,
  DEPLOY_ROOT: process.env.DEPLOY_ROOT,
  NEXT_PUBLIC_PERCY_RUN: process.env.NEXT_PUBLIC_PERCY_RUN,
};
