import { getCookie } from './cookie';

// getViewportWidth :: () -> Number
const getViewportWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

// getViewportHeight :: () -> Number
const getViewportHeight = () => {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
};

// getDocsStateLanguage :: () -> String
const getDocsStateLanguage = () => {
  let lang = '';
  try {
    lang = JSON.parse(localStorage.getItem('plaidDocState')).language;
  } catch (error) {
    // do nothing with the error
  }
  return lang.toLowerCase();
};

// getCurrentCountryCode :: () -> String
const getCurrentCountryCode = () => {
  let cookieData = null;
  try {
    cookieData = localStorage.getItem('cookie-data');
  } catch (e) {
    // no-op
  }
  const currentCountry =
    cookieData != null && cookieData.currentCountry != null
      ? cookieData.currentCountry.toLowerCase()
      : '';
  return currentCountry;
};

// As it is important that this property retains parity with the way
// Mixpanel does detections, the following functions are taken from:
// http://cdn.mxpnl.com/libs/mixpanel-2-latest.js

// This function detects which browser is running this script.
// The order of the checks are important since many user agents
// include key words used in later checks.
const getBrowser = (userAgent, vendor, opera) => {
  vendor = vendor || ''; // vendor is undefined for at least IE9
  if (opera || userAgent.includes(' OPR/')) {
    if (userAgent.includes('Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (
    userAgent.includes('IEMobile') ||
    userAgent.includes('WPDesktop')
  ) {
    return 'Internet Explorer Mobile';
  } else if (userAgent.includes('Edge')) {
    return 'Microsoft Edge';
  } else if (userAgent.includes('FBIOS')) {
    return 'Facebook Mobile';
  } else if (userAgent.includes('Chrome')) {
    return 'Chrome';
  } else if (userAgent.includes('CriOS')) {
    return 'Chrome iOS';
  } else if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
    return 'UC Browser';
  } else if (userAgent.includes('FxiOS')) {
    return 'Firefox iOS';
  } else if (vendor.includes('Apple')) {
    if (userAgent.includes('Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  } else if (userAgent.includes('Android')) {
    return 'Android Mobile';
  } else if (userAgent.includes('Konqueror')) {
    return 'Konqueror';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer';
  } else if (userAgent.includes('Gecko')) {
    return 'Mozilla';
  }
  return `${userAgent} ${vendor} ${opera}`;
};

// This function detects which browser version is running this script,
// parsing major and minor version (e.g., 42.1). User agent strings from:
// http://www.useragentstring.com/pages/useragentstring.php
const getBrowserVersion = (userAgent, vendor, opera) => {
  const browser = getBrowser(userAgent, vendor, opera);
  const versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[browser];
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
};

const getOS = (userAgent) => {
  const a = userAgent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return 'Windows Phone';
    }
    return 'Windows';
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS';
  } else if (/Android/.test(a)) {
    return 'Android';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  } else if (/Mac/i.test(a)) {
    return 'Mac OS X';
  } else if (/Linux/.test(a)) {
    return 'Linux';
  } else {
    return userAgent;
  }
};

const getDevice = (userAgent) => {
  if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
    return 'Windows Phone';
  } else if (/iPad/.test(userAgent)) {
    return 'iPad';
  } else if (/iPod/.test(userAgent)) {
    return 'iPod Touch';
  } else if (/iPhone/.test(userAgent)) {
    return 'iPhone';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else {
    return userAgent;
  }
};

const getReferringDomain = (referrer) => {
  const split = referrer.split('/');
  if (split.length >= 3) {
    return split[2];
  }
  return '';
};

// getDefaultParameters :: () -> Object
const getDefaultParameters = () => {
  return {
    $os: getOS(window.navigator.userAgent),
    $browser: getBrowser(
      window.navigator.userAgent,
      window.navigator.vendor,
      window.opera,
    ),
    $referrer: document.referrer,
    $cookie_referrer: getCookie('referrer_url'),
    $referring_domain: getReferringDomain(document.referrer),
    $referrer_url: getCookie('referrer'),
    $device: getDevice(window.navigator.userAgent),
    $browser_version: getBrowserVersion(
      window.navigator.userAgent,
      window.navigator.vendor,
      window.opera,
    ),
    $screen_height: window.screen.height,
    $screen_width: window.screen.width,
    hash: window.location.hash,
    pathname: window.location.pathname,
    viewportHeight: getViewportHeight(),
    viewportWidth: getViewportWidth(),
    scrollPosition: window.pageYOffset,
    currentCountry: getCurrentCountryCode(),
  };
};

const categoryNumbers = {
  'strictly necessary': 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
};

const thirdParties = {
  TTD: 'targeting',
  '6Sense': 'targeting',
  Marketo: 'performance',
};

const PERFORMANCE_CATEGORIES = new Set([
  'A/B Testing',
  'Analytics',
  'Attribution',
  'Email',
  'Enrichment',
  'Heatmaps & Recordings',
  'Raw Data',
  'Realtime Dashboards',
  'Referrals',
  'Surveys',
  'Tag Managers',
  'Video',
]);

const TARGETING_CATEGORIES = new Set(['Advertising', 'Tag Managers']);

const FUNCTIONAL_CATEGORIES = new Set([
  'CRM',
  'Customer Success',
  'Deep Linking',
  'Helpdesk',
  'Livechat',
  'Performance Monitoring',
  'Personalization',
  'SMS & Push Notifications',
  'Security & Fraud',
]);

const categories = {
  performance: PERFORMANCE_CATEGORIES,
  targeting: TARGETING_CATEGORIES,
  functional: FUNCTIONAL_CATEGORIES,
};

const buildSegmentDestinationCategories = (segmentDestinations) => {
  const segmentDestinationsCategories = {};
  segmentDestinations.forEach((destination) => {
    for (const category in categories) {
      if (categories[category].has(destination.category)) {
        if (!segmentDestinationsCategories[destination.id]) {
          segmentDestinationsCategories[destination.id] = [
            categoryNumbers[category],
          ];
        } else {
          segmentDestinationsCategories[destination.id].push(
            categoryNumbers[category],
          );
        }
      }
    }
  });
  return segmentDestinationsCategories;
};

const getIntegrations = (segmentDestinations, activeGroups) => {
  if (activeGroups.length === 1) {
    return {};
  }
  // setting All to false says that no destination should be enabled unless otherwise specified.
  // setting Segment.io to true means the event will not get dropped before it reaches the debugger
  const integrations = { All: false, 'Segment.io': true };
  const segmentDestinationsCategories =
    buildSegmentDestinationCategories(segmentDestinations);
  for (const destination in segmentDestinationsCategories) {
    integrations[destination] = activeGroups.some((category) => {
      return segmentDestinationsCategories[destination].includes(category);
    });
  }
  return integrations;
};

const getAllPreferences = (segmentDestinations, activeGroups) => {
  const allPreferences = {};
  const segmentDestinationsCategories =
    buildSegmentDestinationCategories(segmentDestinations);
  for (const destination in segmentDestinationsCategories) {
    allPreferences[destination] = activeGroups.includes(
      segmentDestinationsCategories[destination],
    );
  }
  for (const thirdParty in thirdParties) {
    allPreferences[thirdParty] = activeGroups.includes(
      categoryNumbers[thirdParties[thirdParty]],
    );
  }
  return allPreferences;
};

const getCategoryPreferences = (activeGroups) => {
  const categoryPreferences = {};
  for (const category in categories) {
    categoryPreferences[category] = activeGroups.includes(
      categoryNumbers[category],
    );
  }
  return categoryPreferences;
};

export default {
  getViewportWidth,
  getViewportHeight,
  getDefaultParameters,
  getDocsStateLanguage,
  getCurrentCountryCode,
  getIntegrations,
  getAllPreferences,
  getCategoryPreferences,
};
